import React from 'react';


import UxTreatments from './components/UxTreatments/UxTreatments';
import LandingPage from './pages/LandingPage/LandingPage'

import './App.css';

export default class App extends React.Component {

  constructor(props) {

    super(props)

  }


  componentDidMount() {
  }


  render() {
    return (
      <div className='App'>

        <div className='UxBanner'>
          <img className='UxBannerLogo' src='https://aletphysio.co.za/assets/logo.jpg' />
          <h1>Alet Richards Physiotherapy</h1>
          <p>
            With almost two decades experience in various arenas like sport injuries, post surgery and rehabilitation.. Alet is an excellent diagnostician and therapist.
          </p>
        </div>
        <UxTreatments />
        <div className='UxFooter'>
          <div>
            <a href="tel:27724251833">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
            </a> &nbsp;
            <a href="https://www.facebook.com/profile.php?id=100076094151824" target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
            </a>
            <a href="https://www.google.co.za/maps/place/Alet+Richards+Physiotherapy/@-25.7125815,28.2264459,17z/data=!3m1!4b1!4m6!3m5!1s0x1ebfdfcaf11dcb29:0x9b6c988cd6941de1!8m2!3d-25.7125863!4d28.2286346!16s%2Fg%2F11s9c2bm8m" target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-pin-map-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
                <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
              </svg>
            </a>
            <a href="https://wa.me/27724251833?text=Hello%20Alet!" target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-lightning-fill" viewBox="0 0 16 16">
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z" />
              </svg>
            </a>
            <p>
              <img src='https://aletphysio.co.za/assets/map.jpg' />
            </p>
            <p>
              586a 25th avenue (between ben swart and terblanche), Villieria, Pretoria
            </p>
          </div>
        </div>

      </div>
    )
  }

}
