import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import './UxTreatments.css'

export default class UxTreatments extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            redirect: null
        }

    }


    render() {

        return (

            <div className='UxTreatments'>
                <h1>
                    60 minute Treatment
                </h1>
                <div className='AllTreatments'>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_diagnose.jpg'></img>
                        <p>Diagnosis</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_ultrasound.jpg'></img>
                        <p>Sonar</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_back.jpg'></img>
                        <p>Back</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_electro.jpg'></img>
                        <p>Electro</p>
                    </div>

                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_joints.jpg'></img>
                        <p>Joints</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_cupping.jpg'></img>
                        <p>Cupping</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_myofascial.jpg'></img>
                        <p>Release</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_airways.jpg'></img>
                        <p>Airways</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_natal.jpg'></img>
                        <p>Natal</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_strapping.jpg'></img>
                        <p>Strapping</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_needling.jpg'></img>
                        <p>Needling</p>
                    </div>
                    <div className='UxTreatment'>
                        <img src='https://aletphysio.co.za/assets/ap_customexcercise.jpg'></img>
                        <p>Homework</p>
                    </div>

                </div>
            </div>

        )

    }

}
