import React from 'react';

import './LandingPage.css';


class LandingPage extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            redirect: null
        }

    }


    render() {

        return (

            <div className='UxLandingPage'>
            </div>

        )

    }

}


export default LandingPage